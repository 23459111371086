import React from "react";
import Services5 from "components/Services5/services5";
import SContactForm from "components/s-contact-form/s-contact-form";

const Services = () => {


    return (
        <>
            <Services5 />
            <SContactForm noLine />
        </>

    );
};

export const Head = () => {
    return (
        <>
            <title>Services</title>
        </>
    )
}

export default Services;
