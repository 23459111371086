import { Monitor, Smartphone, Film, Share2, PenTool, Clipboard } from 'lucide-react';

export const serviceIcons = {
    0: Monitor,
    1: Smartphone,
    2: Film,
    3: Share2,
    4: PenTool,
    5: Clipboard,
};


