import React from "react";
//new 
import serviceDate from "data/servicepage/sevice.json"
import { serviceIcons } from "data/servicepage/servieIcons";

import * as styles from './custom.module.css'

const Services5 = () => {


  return (
    <section className="services box lficon section-padding position-re">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Best Features
              </h6>
              <h3 className="wow color-font">
                Integrated Digital Solutions for Your Brand
              </h3>

            </div>
          </div>
        </div>
        <div className="row">

          {
            serviceDate.map((item, i) => {
              // Get the icon component based on the index (i)
              const IconComponent = serviceIcons[i];

              return <div
                className="col-lg-6 wow fadeInLeft"
                data-wow-delay={
                  i == 0
                    ? ".5s"
                    : i == 1
                      ? ".7s"
                      : i === 2
                        ? ".9s"
                        : ".5s"
                }
                key={`${item.id}feature`}
              >
                <div className="item-box no-curve">
                  <div>
                    {IconComponent && <IconComponent size={50} strokeWidth={0.5}
                      /* className={`icon color-font `} */ />}
                  </div>
                  <div className={` cont ${styles.minH}`}>
                    <h6>{item.title}</h6>
                    {item.services.map((item, i) => (
                      <p key={i} className={styles.textBlock}>

                        {item.description}
                        <br /><br />
                      </p>
                    ))}

                  </div>
                </div>

              </div>
            })
          }

        </div>
      </div>
      <div className={` line bottom right  ${styles.line}`}></div>
    </section>
  );
};

export default Services5;
